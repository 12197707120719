import React, { useContext, useState } from "react";

import { renameFiles } from "../../../PropertyFiles/components/FileList/renameFiles";
import {
  ACCEPTED_PHOTOS_CONTENT_TYPES,
  AIRBNB_PHOTO_MAX_SIZE
} from "../../../PropertyFiles/constants";
import { createPresignedPost, uploadFileToS3 } from "../../api";
import { HomeInfoContext } from "../../HomeInfoContext";
import { AssetPreview } from "./AssetPreview";
import { Dropzone } from "./Dropzone";

export const AmenityAssets = ({
  amenityTypeId,
  thumbnailUrl,
  amenityStatus
}) => {
  const [updatingAsset, setUpdatingAsset] = useState(false);
  const [progress, setProgress] = useState(null);

  const {
    ACCESSIBILITY_STATUS_IN_REVIEW
  } = window.AirbaseConstants.HomeInfoAmenity;

  const {
    property,
    addPendingFile,
    removePendingFile,
    amenityAssets
  } = useContext(HomeInfoContext);

  const assets = amenityAssets.find(
    ({ amenityTypeId: currentAmenityTypeId }) =>
      currentAmenityTypeId === amenityTypeId
  );

  const assetSrc = assets?.pendingAsset?.src ?? thumbnailUrl;

  if (!updatingAsset && assetSrc)
    return (
      <AssetPreview
        src={assetSrc}
        onEdit={() => {
          setUpdatingAsset(true);
        }}
        actionsDisabled={amenityStatus === ACCESSIBILITY_STATUS_IN_REVIEW}
        actionsDisabledReason="Photo editing is disabled while amenity is in review"
        showRemove={Boolean(thumbnailUrl && assets?.pendingAsset)}
        onRemove={() => {
          removePendingFile(amenityTypeId);
        }}
      />
    );

  return (
    <Dropzone
      single
      acceptContentTypes={ACCEPTED_PHOTOS_CONTENT_TYPES}
      maxSize={AIRBNB_PHOTO_MAX_SIZE}
      showGoBack={updatingAsset}
      progress={progress}
      onGoBack={() => {
        setUpdatingAsset(false);
      }}
      onDrop={async (files) => {
        const {
          FOLDER_NAME_ACCESSIBILITY
        } = window.AirbaseConstants.PropertyFile;

        const [file] = renameFiles(files);

        setProgress(0);

        const { url, fields } = await createPresignedPost({
          property_id: property.id,
          folder_name: FOLDER_NAME_ACCESSIBILITY,
          file_name: file.name,
          content_type: file.type
        });

        const { key } = await uploadFileToS3(
          url,
          fields,
          file,
          ({ loaded, total }) => {
            const progressPercents = Math.floor((loaded * 100) / total);
            setProgress(progressPercents);
          }
        );

        addPendingFile(
          {
            key,
            fileName: file.name,
            contentType: file.type,
            fileSize: file.size,
            propertyId: property.id,
            folderName: FOLDER_NAME_ACCESSIBILITY
          },
          `${url}/${key}`,
          amenityTypeId
        );

        setUpdatingAsset(false);
        setProgress(null);
      }}
    />
  );
};
