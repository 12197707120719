import { Formik } from "formik";
import React, { useContext, useMemo } from "react";
import * as yup from "yup";

import { Toggle } from "../../../../common/forms/Toggle";
import { SelectOptions } from "../../../../common/SelectOptions";
import { FormGroup } from "../components/FormGroup";
import { HomeInfoContext } from "../HomeInfoContext";
import { HomeInfoForm } from "../HomeInfoForm";
import { SIDEBAR_NAMES } from "../sections";

const schema = yup.object({
  home_info_availability_rule_attributes: yup.object({
    preparation_time_days: yup
      .number()
      .min(0, "Value must be greater than or equal to 0")
      .max(2, "Value must be less than or equal to 2")
      .typeError("Value must be a number")
  })
});

export const AvailabilityRules = () => {
  const { homeInfo = {}, updateHomeInfo } = useContext(HomeInfoContext);
  const AVAILABILITY_WINDOW_OPTIONS = [
    [-1, "Calendar open"],
    [0, "Block all future dates"],
    [30, "30 days"],
    [60, "60 days"],
    [90, "90 days"],
    [120, "120 days"],
    [150, "150 days"],
    [180, "180 days"],
    [210, "210 days"],
    [240, "240 days"],
    [270, "270 days"],
    [300, "300 days"],
    [330, "330 days"],
    [365, "365 days"]
  ];

  const initialValues = useMemo(
    () => ({
      ...homeInfo,
      home_info_availability_rule_attributes: {
        ...homeInfo.home_info_availability_rule_attributes,
        multiplat_advance_notice_hours:
          typeof homeInfo.home_info_availability_rule_attributes
            .multiplat_advance_notice_hours === "number"
            ? homeInfo.home_info_availability_rule_attributes
                .multiplat_advance_notice_hours
            : 48
      }
    }),
    [homeInfo]
  );

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={updateHomeInfo}
      validationSchema={schema}
    >
      {({ setFieldValue, values }) => (
        <HomeInfoForm sidebarName={SIDEBAR_NAMES.PLATFORM_SETTINGS}>
          <h4 className="section-name">Trip Length</h4>

          <FormGroup
            name="home_info_availability_rule_attributes.default_maximum_nights"
            label="Default maximum nights"
          />

          <Toggle
            id="allow_request_longer_stays"
            label="Allow requests to book for longer stays"
            checked={
              values.home_info_availability_rule_attributes
                .allow_request_longer_stays
            }
            onChange={() => {
              const newValue = !values.home_info_availability_rule_attributes
                .allow_request_longer_stays;

              setFieldValue(
                "home_info_availability_rule_attributes.allow_request_longer_stays",
                newValue
              );
            }}
          />

          <h4 className="section-name">Availability</h4>

          <FormGroup
            name="home_info_availability_rule_attributes.preparation_time_days"
            label="Preparation time (days)"
          />

          <FormGroup
            name="home_info_availability_rule_attributes.advance_notice_hours"
            label="Advance notice (hours)"
          />

          <FormGroup
            name="home_info_availability_rule_attributes.multiplat_advance_notice_hours"
            label="Guesty advance notice (hours)"
            component="select"
          >
            <SelectOptions options={[[0, "0-24"], 48, 72, 168]} />
          </FormGroup>

          <Toggle
            id="allow_request_advanced_notice_breached"
            label="Allow requests to book if advance notice breached"
            checked={
              values.home_info_availability_rule_attributes
                .allow_request_advanced_notice_breached
            }
            onChange={() => {
              const newValue = !values.home_info_availability_rule_attributes
                .allow_request_advanced_notice_breached;

              setFieldValue(
                "home_info_availability_rule_attributes.allow_request_advanced_notice_breached",
                newValue
              );
            }}
          />

          <FormGroup
            name="home_info_availability_rule_attributes.availability_window_days"
            label="Availability window"
            component="select"
          >
            <SelectOptions
              includeBlank={false}
              options={AVAILABILITY_WINDOW_OPTIONS}
            />
          </FormGroup>
        </HomeInfoForm>
      )}
    </Formik>
  );
};
