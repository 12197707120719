import { selectCurrentFolderName } from "../files/selectors";
import { fetchFiles } from "../files/thunks";
import { selectPropertyId } from "../property/selectors";
import {
  uploadFileNotifyProgress,
  uploadFileRequest,
  uploadFileResponse,
  uploadFilesRequest
} from "./actions";
import { selectQueuedFileItem } from "./selectors";

const { FOLDER_NAME_AIRBNB } = window.AirbaseConstants.PropertyFile;

const uploadFile = (fileItem) => async (dispatch, getState, { api }) => {
  dispatch(uploadFileRequest(fileItem));

  try {
    const file = {
      property_id: selectPropertyId(getState()),
      folder_name: selectCurrentFolderName(getState()),
      file_name: fileItem.name,
      file_size: fileItem.size,
      content_type: fileItem.type
    };

    const uploadedFile = await api.uploadFile(
      {
        file,
        fileItem
      },
      (progressEvent) =>
        dispatch(uploadFileNotifyProgress(fileItem, progressEvent))
    );

    dispatch(uploadFileResponse(fileItem, uploadedFile));
  } catch (error) {
    dispatch(uploadFileResponse(fileItem, error));
  }
};

export const uploadFiles = (files) => async (dispatch, getState) => {
  dispatch(uploadFilesRequest(files));

  // eslint-disable-next-line no-restricted-syntax
  for (const fileItem of files) {
    // eslint-disable-next-line no-await-in-loop
    await dispatch(uploadFile(fileItem));
  }

  // If files were added to Airbnb folder, we need to refresh the files list
  // As images where copied to the Guesty at the backend
  if (
    selectCurrentFolderName(getState()) === FOLDER_NAME_AIRBNB &&
    files.length > 0
  ) {
    dispatch(fetchFiles());
  }
};

export const retryFileUpload = (fileName) => (dispatch, getState) => {
  const fileItem = selectQueuedFileItem(getState(), fileName);
  dispatch(uploadFile(fileItem));
};
