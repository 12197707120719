import React from "react";

import { SORT_ASC } from "../../data/files/reducer";
import { renderWithMockStore } from "../../data/testHelpers";
import { FileThumbnail } from "./FileThumbnail";

describe("<FileThumbnail />", () => {
  function renderComponent(file) {
    const initialState = {
      files: {
        sorting: { field: "created_at", direction: SORT_ASC },
        selection: { ids: [] }
      }
    };

    return renderWithMockStore(<FileThumbnail file={file} />, { initialState });
  }

  describe("for image", () => {
    const file = {
      file_name: "image001.jpg",
      content_type: "image/jpeg",
      image_url: "https://imagekit.com/preview-image.jpg",
      image_thumbnail_url: "https://imagekit.com/small-image.jpg"
    };

    it("renders a thumbnail along with fancy box", () => {
      const { container } = renderComponent(file);

      expect(container).toMatchInlineSnapshot(`
        <div>
          <div
            class="file-thumbnail-container"
          >
            <div
              class="file-top-menu"
            >
              <input
                type="checkbox"
              />
            </div>
            <button
              class="file-thumbnail"
              href="https://imagekit.com/preview-image.jpg"
              rel="noopener noreferrer"
              target="_blank"
              type="button"
            >
              <img
                alt="image001.jpg"
                class="card-img-top"
                src="https://imagekit.com/small-image.jpg"
              />
            </button>
          </div>
        </div>
      `);
    });
  });

  describe("for other type", () => {
    const file = {
      file_name: "document.doc",
      content_type: "application/msword",
      public_url: "https://s3.com/document.doc"
    };

    it("renders a preview link along with the icon", () => {
      const { container } = renderComponent(file);

      expect(container).toMatchInlineSnapshot(`
        <div>
          <div
            class="file-thumbnail-container"
          >
            <div
              class="file-top-menu"
            >
              <input
                type="checkbox"
              />
            </div>
            <button
              class="file-thumbnail"
              href="https://docs.google.com/gview?url=https://s3.com/document.doc"
              rel="noopener noreferrer"
              target="_blank"
              type="button"
            >
              <i
                class="fa fa-file-word"
              />
            </button>
          </div>
        </div>
      `);
    });
  });
});
