import { ACCEPTED_PHOTOS_CONTENT_TYPES } from "./constants";

const createImgElement = (src) =>
  new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => resolve(img);
    img.onerror = reject;

    img.src = src;
  });

export const validateFiles = async (files, dropzoneSettings) => {
  if (!dropzoneSettings) {
    return [];
  }

  const { acceptContentTypes, maxSize, validator } = dropzoneSettings;

  const errors = await Promise.all(
    files.map(
      async ({
        content_type: contentType,
        public_url: publicUrl,
        file_size: fileSize,
        file_name: fileName
      }) => {
        if (!acceptContentTypes.includes(contentType)) {
          return {
            code: "invalid_file_type",
            message: `File ${fileName} can't be used. File type must be one of ${acceptContentTypes.join(
              ", "
            )}`
          };
        }

        if (maxSize && fileSize > maxSize.size) {
          return {
            code: "invalid_file_size",
            message: `File ${fileName} can't be used. File size cannot exceed ${maxSize.formattedSize}`
          };
        }

        if (validator) {
          const img = ACCEPTED_PHOTOS_CONTENT_TYPES.includes(contentType)
            ? await createImgElement(publicUrl)
            : null;
          return validator({ type: contentType, image: img });
        }

        return null;
      }
    )
  );

  return errors.filter(Boolean);
};
