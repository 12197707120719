import { Fancybox } from "@fancyapps/ui";
import { find } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  changeCurrentFolder,
  changeFilesSortingField,
  toggleFilesSortingDirection
} from "../PropertyFiles/data/files/actions";
import {
  selectLoadingFiles,
  selectSortedFiles
} from "../PropertyFiles/data/files/selectors";
import { fetchFiles, updateFiles } from "../PropertyFiles/data/files/thunks";
import { fetchPropertyResponse } from "../PropertyFiles/data/property/actions";

const { config: fancyboxConfig } = window.AirbaseConstants.fancybox;

export const useFileGallery = (folderName, property) => {
  const dispatch = useDispatch();
  const [locallySortedFiles, setLocallySortedFiles] = useState([]);
  const files = useSelector(selectSortedFiles);
  useEffect(() => {
    // As we're using same fetching methods as for property files under the hood
    // we have to change the current folder to the one that is used for listing
    // to make selectSortedFiles selector work properly
    dispatch(changeCurrentFolder(folderName));
    dispatch(changeFilesSortingField("order"));
    dispatch(toggleFilesSortingDirection()); // Set to ASC
  }, [dispatch, folderName]);

  useEffect(() => {
    // Set current property on init, as it's needed later
    dispatch(fetchPropertyResponse(property));
    // Fetch on init
    dispatch(fetchFiles());
  }, [dispatch, property]);

  useEffect(() => {
    setLocallySortedFiles(files);
  }, [files]);

  const loading = useSelector(selectLoadingFiles);

  const handleGalleryInit = useCallback(
    (startIndex) => () => {
      const galleryItems = files.map(({ public_url, file_name, notes }) => ({
        src: public_url,
        caption: `${file_name}<br/>${notes || ""}`
      }));

      Fancybox.show(galleryItems, { ...fancyboxConfig, startIndex });
    },
    [files]
  );

  const findFile = useCallback(
    (id) => {
      const file = find(locallySortedFiles, { id });

      return { file, index: locallySortedFiles.indexOf(file) };
    },
    [locallySortedFiles]
  );

  // Handles files sorting while dragging over to new position
  const moveFile = useCallback(
    (id, newIndex) => {
      const { file, index: oldIndex } = findFile(id);
      const newFiles = [...locallySortedFiles];
      newFiles.splice(oldIndex, 1); // Remove file from old position
      newFiles.splice(newIndex, 0, file); // Insert file to new position

      newFiles.forEach((f, i) => {
        // eslint-disable-next-line no-param-reassign
        f.order = i + 1;
      });

      setLocallySortedFiles(newFiles);
    },
    [findFile, locallySortedFiles]
  );

  const submitNewOrder = async () => {
    const newOrder = locallySortedFiles.map(({ id, order }) => ({
      id,
      values: { order }
    }));

    await dispatch(updateFiles(newOrder));
  };

  return {
    loading,
    files,
    findFile,
    moveFile,
    handleGalleryInit,
    locallySortedFiles,
    submitNewOrder
  };
};
