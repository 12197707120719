import React, { useState } from "react";

export const Row = ({ details, label, value, tooltip }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      {details ? (
        <button
          type="button"
          className="label"
          onClick={() => setExpanded(!expanded)}
        >
          {label}
        </button>
      ) : (
        <div className="label">
          {tooltip && <span className="tooltip-text">{tooltip}</span>}
          {label}
        </div>
      )}

      <div className="value">
        {value}{" "}
        {details && (
          <i className={`fa fa-chevron-down ${expanded ? "expanded" : ""}`} />
        )}
      </div>
      {expanded && details}
    </>
  );
};
